// Import css
import './scss/index.scss';

if (typeof window.Steenify === 'undefined') {
  window.Steenify = {};
}
window.Steenify = {
  init: function () {
    Steenify.init__scroll__page();
    Steenify.init__section__1();
    Steenify.init__check__scroll();
  },

  init__scroll__page: function () {
    this.fullPage = new fullpage('#fullpage', {
      autoScrolling: true,
      scrollHorizontally: true,
      scrollingSpeed: 700,
      // lockAnchors: true,
      scrollOverflow: true,
      responsiveWidth: 991,
      responsiveHeight: 500,
      // scrollBar: true,
      anchors: [
        'banner_intro',
        'graphic_project',
        'ui_ux_project',
        'social_project',
        'new_brand',
        'beyond_creative',
      ],
      onLeave: function (origin, destination, direction) {
        var anchor_leave = destination.anchor;
        if (anchor_leave === 'graphic_project') {
          Steenify.init__animate__graphic_project();
        }
        if (anchor_leave === 'ui_ux_project') {
          Steenify.init__animate__ui_ux_project();
        }
        if (anchor_leave === 'social_project') {
          Steenify.init__animate__social_project();
        }
        if (anchor_leave === 'new_brand') {
          Steenify.init__animate__new_brand();
        }
        if (anchor_leave === 'beyond_creative') {
          Steenify.init__animate__beyond_creative();
        }
      },
      afterLoad: function (origin, destination, direction) {
        Steenify.hanlde_remove_animation(origin.anchor);
      },
      afterRender: function () {
        setTimeout(function () {
          $('.page__loader').fadeOut(1000, function () {
            $(this).hide();
            // Steenify.init_animatition_banner();
          });
        }, 1000);
      },
    });

    var fullPage = this.fullPage;
    $('.project_detail__cta').on('click', function () {
      var target = $(this).data('target');
      $(target).modal('show');

      // fullPage.setAutoScrolling(false);
    });

    // $('#graphic_project__detail').on('hidden.bs.modal', function (e) {
    //   fullPage.setAutoScrolling(true);
    // });
    // $('#social_project__detail').on('hidden.bs.modal', function (e) {
    //   fullPage.setAutoScrolling(true);
    // });
    // $('#ui_ux_project__detail').on('hidden.bs.modal', function (e) {
    //   fullPage.setAutoScrolling(true);
    // });

    $('.banner_intro__text').on('click', function () {
      fullPage.moveSectionDown();
    });
  },

  init__section__1: function () {
    var canvas = $('#mycanvas');
    var banner_intro__text = $('.banner_intro__text');
    var fullPage = this.fullPage;

    var devicePixelRatio = window.devicePixelRatio || 1;

    var _w = window.innerWidth;
    var _h = window.innerHeight;

    PIXI.settings.ROUND_PIXELS = true;
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;

    var renderer = new PIXI.Renderer({
      view: canvas[0],
      width: _w,
      height: _h,
      autoDensity: true,
      antialias: true,
      transparent: true,
      backgroundColor: 0x000000,
    });

    window.addEventListener('resize', resize);

    function resize() {
      _w = window.innerWidth;
      _h = window.innerHeight;
      renderer.resize(_w, _h);
    }

    var stage = new PIXI.Container();

    // Drawing stuff

    var background = PIXI.Sprite.from(
      '/public/img/background__landingapage.jpg'
    );

    background.x = 0;
    background.y = 0;
    background.width = renderer.width;
    background.height = renderer.height;

    stage.addChild(background);

    var point1 = new PIXI.Graphics();
    point1.lineStyle(2, 0xffffff);
    point1.beginFill(0xffffff);
    point1.drawCircle(0, 0, 4.5);
    point1.endFill();

    point1.x = renderer.width / 2;
    point1.y = renderer.height / 2;
    point1.interactive = true;
    point1.buttonMode = true;

    var onclickPoint = function () {
      fullPage.moveSectionDown();
    };

    point1.on('mousedown', onclickPoint);
    point1.on('touchstart', onclickPoint);

    stage.addChild(point1);

    var point2 = new PIXI.Graphics();
    point2.lineStyle(2, 0xffffff);
    point2.beginFill(0xffffff);
    point2.drawCircle(0, 0, 4.5);
    point2.endFill();

    point2.x = 120;
    point2.y = renderer.height / 3;

    stage.addChild(point2);

    var point3 = new PIXI.Graphics();
    point3.lineStyle(2, 0xffffff);
    point3.beginFill(0xffffff);
    point3.drawCircle(0, 0, 4.5);
    point3.endFill();

    point3.x = 120;
    point3.y = (renderer.height / 3) * 2;

    stage.addChild(point3);

    var line1 = new PIXI.Graphics();
    line1.lineStyle(1, 0xffffff);
    line1.moveTo(120, renderer.height / 3);
    line1.lineTo(renderer.width / 2, renderer.height / 2);
    line1.closePath();

    stage.addChild(line1);

    var line2 = new PIXI.Graphics();
    line2.lineStyle(1, 0xffffff);
    line2.moveTo(120, (renderer.height / 3) * 2);
    line2.lineTo(renderer.width / 2, renderer.height / 2);
    line2.closePath();

    stage.addChild(line2);

    stage.interactive = true;

    var onPointerMove = (e) => {
      var global = e.data.global;
      var banner_intro__text_W = banner_intro__text.width();

      if (global.y > renderer.height) {
        return;
      }

      if (global.x + banner_intro__text_W + 130 < renderer.width) {
        point1.x = global.x;
      } else {
        point1.x = point1.x;
      }
      point1.y = global.y;

      banner_intro__text.css({
        top: point1.y + 'px',
        left: point1.x + 'px',
      });

      line1.clear();
      line1.lineStyle(1, 0xffffff);
      line1.moveTo(120, renderer.height / 3);
      line1.lineTo(point1.x, point1.y);
      line1.closePath();

      line2.clear();
      line2.lineStyle(1, 0xffffff);
      line2.moveTo(120, (renderer.height / 3) * 2);
      line2.lineTo(point1.x, point1.y);
      line2.closePath();
    };

    stage.on('pointermove', onPointerMove);

    var ticker = new PIXI.Ticker();
    ticker.add(animate);
    ticker.start();

    function animate() {
      point2.x = 120;
      point2.y = renderer.height / 3;

      point3.x = 120;
      point3.y = (renderer.height / 3) * 2;

      background.width = renderer.width;
      background.height = renderer.height;

      renderer.render(stage);
    }
  },

  hanlde_remove_animation: function (anchor_leave) {
    if (anchor_leave === 'graphic_project') {
      // graphic
      var graphic_project = $('#graphic_project_sec');
      var graphic_project__svg = graphic_project.find('.graphic_project__svg');
      var svg__animate = graphic_project.find('.svg__animate');
      svg__animate.removeClass('animate__animated');
      graphic_project__svg.css('color', '#5bcdcd');
      graphic_project__svg.removeClass('active');
    }
    if (anchor_leave === 'ui_ux_project') {
      // ui ux
      var ui_project = $('#ui_ux_project_sec');
      var ui_ux_project__img__item = ui_project.find(
        '.ui_ux_project__img__item'
      );
      ui_project.removeClass('active');
      ui_ux_project__img__item.removeClass('animate__animated');
    }
    if (anchor_leave === 'social_project') {
      // social
      var social_project_sec = $('#social_project_sec');
      var social_project__item = social_project_sec.find(
        '.social_project__item'
      );
      social_project_sec.removeClass('active');
      social_project__item.removeClass('animate__animated');
    }
    if (anchor_leave === 'new_brand') {
      // new brand
      var new_brand_sec = $('#new_brand_sec');
      var new_brand__item = new_brand_sec.find('.new_brand__item');
      var new_brand__video = new_brand_sec.find('.new_brand__video');
      new_brand__video[0].currentTime = 0;
      new_brand_sec.removeClass('active');
      new_brand__item.removeClass('animate__animated');
    }
    if (anchor_leave === 'beyond_creative') {
      // beyond sec
      var beyond_creative_sec = $('#beyond_creative_sec');
      var beyond_creative__item = beyond_creative_sec.find(
        '.beyond_creative__item'
      );
      beyond_creative_sec.removeClass('active');
      beyond_creative__item.removeClass('animate__animated');
    }
  },

  init__animate__graphic_project: function () {
    var graphic_project = $('#graphic_project_sec');
    var graphic_project__svg = graphic_project.find('.graphic_project__svg');
    var svg__animate = graphic_project.find('.svg__animate');
    if (!graphic_project__svg.hasClass('active')) {
      svg__animate.addClass('animate__animated');
      setTimeout(function () {
        graphic_project__svg.css('color', '#073344');
      }, 1000);
      graphic_project__svg.addClass('active');
    }
  },
  init__animate__ui_ux_project: function () {
    var ui_project = $('#ui_ux_project_sec');
    var ui_ux_project__img__item = ui_project.find('.ui_ux_project__img__item');

    if (!ui_project.hasClass('active')) {
      ui_project.addClass('active');
      ui_ux_project__img__item.addClass('animate__animated');
    }
  },
  init__animate__social_project: function () {
    var social_project_sec = $('#social_project_sec');
    var social_project__item = social_project_sec.find('.social_project__item');

    if (!social_project_sec.hasClass('active')) {
      social_project_sec.addClass('active');
      social_project__item.addClass('animate__animated');
    }
  },
  init__animate__new_brand: function () {
    var new_brand_sec = $('#new_brand_sec');
    var new_brand__item = new_brand_sec.find('.new_brand__item');
    var new_brand__video = new_brand_sec.find('.new_brand__video');
    if (!new_brand_sec.hasClass('active')) {
      new_brand_sec.addClass('active');
      new_brand__item.addClass('animate__animated');
    }
    new_brand__video.trigger('play');
  },
  init__animate__beyond_creative: function () {
    var beyond_creative_sec = $('#beyond_creative_sec');
    var beyond_creative__item = beyond_creative_sec.find(
      '.beyond_creative__item'
    );

    if (!beyond_creative_sec.hasClass('active')) {
      beyond_creative_sec.addClass('active');
      beyond_creative__item.addClass('animate__animated');
    }
  },
  init__check__scroll: function () {
    var page__section = $('[data-section]');

    function checkScroll() {
      if ($(window).width() < 991) {
        var top = $(window).scrollTop();

        for (var i = 0; i < page__section.length; i++) {
          if (top - $(page__section[i]).offset().top < 0) {
            var sec = $(page__section[i]).data('section');
            if (sec === 'graphic_project') {
              Steenify.init__animate__graphic_project();
            }
            if (sec === 'ui_ux_project') {
              Steenify.init__animate__ui_ux_project();
            }
            if (sec === 'social_project') {
              Steenify.init__animate__social_project();
            }
            if (sec === 'new_brand') {
              Steenify.init__animate__new_brand();
            }
            if (sec === 'beyond_creative') {
              Steenify.init__animate__beyond_creative();
            }
          }
        }
      }
    }
    checkScroll();
    $(window).on('scroll', checkScroll);
  },
};

//***************************************
//      Main program
//***************************************

$(function () {
  Steenify.init();
});
